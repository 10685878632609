<template>
    <div class="wrapper">
        <div class="h1">События пользователя</div>
        <div class="underline mb-4">
            <div class="mb-2">Созданные события</div>
            <div class="grey-table auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :fields="createdForumsFields"
                    :items="forumsProvider"
                    class="mb-0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    empty-text="Нет доступных событий"
                    show-empty
                    thead-class="d-none"
                    ref="userForums"
                >
                    <template #cell(title)="data" style="width:25%">
                        <div class="forum-td">
                            <img v-if="data.item.imagePath" :src="data.item.imagePath" alt="">
                            {{ data.item.title }}
                        </div>
                    </template>

                    <template #cell(dateStart)="data">
                        {{parseDate(data.item.dateStart)}}
                    </template>

                    <template #cell(status)="data">
                        <forum-status :status="data.item.status" />
                    </template>

                    <template #cell(details)="data">
                        <router-link :to="{ name: 'forum-details', params: { forumId: data.item._id } }" class="btn-crl">
                            <b-icon icon="eye-fill"></b-icon>
                        </router-link>
                    </template>

                    <template #cell(edit)="data">
                        <router-link :to="{ name: 'forum-edit', params: { forumId: data.item._id } }" class="btn-crl">
                            <b-icon icon="pencil-fill"></b-icon>
                        </router-link>
                    </template>

                    <template #cell(delete)="data">
                        <button class="btn-crl" @click="handleDeleteForum(data.item._id)">
                            <b-icon icon="trash"></b-icon>
                        </button>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                </b-table>
            </div>
            <div v-if="totalForums > perPage" class="pt-3">
                <b-pagination class="mb-0" first-number last-number :total-rows="totalForums" :per-page="perPage" v-model="currentPage"/>
            </div>
        </div>
        <div class="mb-2">Запросы на участие в событии</div>
        <div class="grey-table mb-4 auto-scroll-horizontal">
            <b-table
                :fields="forumRegistrationFields"
                :items="forumRegistrationItems"
                class="mb-0"
                empty-text="Нет запросов на участие в событиях"
                show-empty
                thead-class="d-none"
            >
                <template #cell(title)="data" style="width:25%">
                    <div class="forum-td">
                        <img v-if="data.item.forum.imagePath" :src="data.item.forum.imagePath" alt="">
                        {{ data.item.forum.title }}
                    </div>
                </template>

                <template #cell(dateStart)="data">
                    {{parseDate(data.item.forum.dateStart)}}
                </template>

                <template #cell(visit)="data">
                    {{data.item.visit ? 'Посетил(а)' : 'Не посетил(а)'}}
                </template>

                <template #cell(status)="data">
                    <request-status :status="data.item.status"/>
                </template>

                <template #cell(payment)="data">
                    {{ data.item.payment ? 'Оплачено' : 'Не оплачено' }}
                </template>

                <template #cell(edit)="data">
                    <router-link :to="{ name: 'forum-edit', params: { forumId: data.item.forum._id } }" class="btn-crl">
                        <b-icon icon="pencil-fill"></b-icon>
                    </router-link>
                </template>

                <template #cell(view)="data">
                    <router-link :to="{ name: 'forum-details', params: { forumId: data.item.forum._id } }" class="btn-crl">
                        <b-icon icon="eye-fill"></b-icon>
                    </router-link>
                </template>

                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>

                <template #empty="scope">
                    <div class="text-center my-2">
                        {{ scope.emptyText }}
                    </div>
                </template>

            </b-table>
        </div>
        <ValidationObserver ref="inviteForum">
            <b-form slot-scope="{ validate }" @submit.prevent="handleSubmit">
                <div class="row">
                    <ValidationProvider rules="required" name="Событие" tag="div" class="col-xl-4 col-md-7">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <v-select :class="['dropdown-up', errors.length ? 'is-invalid' : '']" placeholder="Выберите событие" :options="requestForumsOption" v-model="selectForum" label="title">
                                <template #no-options="{ search, searching, loading }">
                                    Извините, нет доступных событий
                                </template>

                                <template #option="{ imagePath, title }">
                                    <div class="forum-td">
                                        <img v-if="imagePath" :src="imagePath" alt="">
                                        <div class="forum-td__title">
                                            {{ title }}
                                        </div>
                                    </div>
                                </template>
                            </v-select>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <div v-if="selectForum && selectForum.price.length" class="col-xl-4 col-md-7">
                        <ValidationProvider rules="required" name="Тип билета" tag="div">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <v-select :class="['dropdown-up', errors.length ? 'is-invalid' : '']" placeholder="Выберите тип билета" :options="selectForum.price" v-model="ticket" label="title">
                                    <template #no-options="{ search, searching, loading }">
                                        Извините, нет доступных билетов
                                    </template>

                                    <template #option="{ title, amount }">
                                        {{ title }} - {{ amount }} грн.
                                    </template>

                                    <template #option="{ title, amount }">
                                        {{ title }} - {{ amount }} грн.
                                    </template>
                                </v-select>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div :class="selectForum && selectForum.price.length ? 'col-xl-4 col-md-7' : 'col-xl-6 col-md-5'">
                        <b-button type="submit" variant="primary submit pl-5 pr-5">пригласить</b-button>
                    </div>
                </div>
            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import parseDate from '@/mixins/parseDate'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import RequestStatus from "./RequestStatus";
    import ForumStatus from "../forum/ForumStatus";

    export default {
        name: "UserEvent",
        components: {
            ForumStatus,
            RequestStatus,
        },
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        mixins: [parseDate],
        computed: {
            getRequestForums(){
                const data = {
                    byLevelup: true,
                    perPage: 100500,
                    draft: false
                };
                this.getForumsList(data).then(forums => {
                    this.requestForumsOption = forums.results;
                })
            },
            getUserForumByUserId(){
                const data = {
                    userId: this.user._id
                };
                this.getUserForum(data).then(users => {
                    this.forumRegistrationItems = users.results;
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            }
        },
        methods: {
            ...mapActions([
                'getUserEvent',
                'getForumsList',
                'inviteUserForum',
                'getUserForum',
                'deleteForum'
            ]),
            forumsProvider(ctx){
                const data = {
                    ownerId: this.user._id,
                    page: ctx.currentPage,
                };

                return this.getUserEvent(data).then(forums => {
                    this.totalForums = forums.totalCount;
                    this.createdForums = forums.results;
                    return this.createdForums;
                })
                    .catch(error => {
                        errorsAlertApi(error);
                        return []
                    });

            },
            async handleSubmit() {
                const isValid = await this.$refs.inviteForum.validate();
                if (isValid) {
                    const data = {
                        user: this.user._id,
                        forum: this.selectForum._id,
                        status: "APPROVED"
                    };
                    const language = this.user.languageCode;

                    if(this.ticket){
                        data.ticketTitle = this.ticket.title
                    }

                    this.inviteUserForum({ data, language}).then(invite => {
                        this.forumRegistrationItems.push(invite);
                        this.selectForum = null;

                        this.$nextTick(() => {
                            this.$refs.inviteForum.reset();
                        });
                    })
                        .catch(error => {
                            errorsAlertApi(error);
                        });
                }
            },
            handleDeleteForum(forumId){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить событие?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteForum(forumId).then(() => {
                            this.$refs.userForums.refresh();
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })
                    }
                })
            }
        },
        created(){
            this.getRequestForums;
            this.getUserForumByUserId;
        },
        data(){
            return{
                requestForumsOption: [],
                isBusy: false,
                selectForum: null,
                ticket: null,
                createdForumsFields: [
                    {
                        key: 'title',
                    }, {
                        key: 'description',
                    }, {
                        key: 'dateStart',
                    }, {
                        key: 'status',
                    }, {
                        key: 'details',
                        tdClass: 'icon-action'
                    }, {
                        key: 'edit',
                        tdClass: 'icon-action'
                    }, {
                        key: 'delete',
                        tdClass: 'icon-action'
                    }
                ],
                createdForums: [],
                currentPage: 1,
                perPage: 10,
                totalForums: 0,
                forumRegistrationFields: [
                    {
                        key: 'title',
                    }, {
                        key: 'dateStart',
                    }, {
                        key: 'visit',
                    }, {
                        key: 'payment'
                    }, {
                        key: 'status',
                    }, {
                        key: 'edit',
                        tdClass: 'icon-action'
                    }, {
                        key: 'view',
                        tdClass: 'icon-action'
                    }
                ],
                forumRegistrationItems: [],
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bi-eye-fill{
        font-size: 25px;
    }
    .bi-pencil-fill{
        font-size: 18px;
    }
</style>