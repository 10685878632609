<template>
    <div class="dashboard">
        <router-link :to="backTo" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>назад к списку</span>
        </router-link>
        <div v-if="user">
            <user-info :user="user" />
            <user-event :user="user" />
        </div>
        <div v-else-if="isUserData" class="wrapper text-center">
            <b-spinner></b-spinner>
        </div>
        <div class="wrapper" v-if="!isUserData">Пользователя не существует</div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import UserInfo from '@/components/user/UserInfo'
    import UserEvent from '@/components/user/UserEvent'

    export default {
        name: "user-details",
        components: {
            UserInfo,
            UserEvent
        },
        computed: {
            ...mapGetters([
                'userByID'
            ]),
            getUserId(){
                return this.$route.params.userId
            },
            backTo(){
                if(this.$route.params.hasOwnProperty('from') && this.$route.params.from === 'admins'){
                    return { name: 'admins' }
                }
                return { name: 'users' }
            }
        },
        methods: {
            ...mapActions([
                'getUserById',
                'getUserEvent',
            ]),
        },
        mounted(){
            this.getUserById(this.getUserId).then(user => {
                this.user = user;
            })
                .catch(() => {
                    this.isUserData = false;
                })
        },
        data(){
            return {
                user: null,
                isUserData: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>